import React, { useCallback, useContext, useEffect, useState } from "react";
import "./Pubmed.css";
import { MdContentCopy } from "react-icons/md";
import {
  get_all_disease_tagged,
  get_disease_tagged,
  new_chat_session,
  pubmedSearch,
  pubmedSearchSummary,
  referencePapers,
} from "../../api/Postaction";
import { toast } from "react-toastify";
import { MyContext } from "../../context/ContextApi";
import { CopyToClipboard } from "react-copy-to-clipboard";
import DiseaseTable from "../files/DiseaseTable";
import DiseaseNameList from "../files/DiseaseNameList";
import CommonLoader from "../../common/commonLoader";
import {
  delete_disease_tagged_db,
  delete_file_s3_pub,
} from "../../api/DeleteAction";
import { commonStr } from "../../utils/constants/commonStrings";
import { shareIcon } from "../../utils/images";
import CommonBtn from "../../common/commonBtn";
import { ChatBot, SearchInputArrowBtn } from "../../common";

const PubmedQuery = () => {
  const {
    filterContent,
    isTableVisible,
    isDiseaseListShow,
    commonLoading,
    setFilterContent,
  } = useContext(MyContext);
  const [selectedData, setSelectedData] = useState([]);

  const [data, setData] = useState([]);
  const [Loading, setLoading] = useState();
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [diseaseTags, setDiseaseTags] = useState([]);
  const [searchInputVal, setSearchInputVal] = useState("");
  const [diseaseTableList, setDiseaseTableList] = useState("");

  const [isShowFileWithBack, setIsShowFileWithBack] = useState(false);
  const [isSearchTableShow, setIsSearchTableShow] = useState(true);
  const [isOverAllSummary, setIsOverAllSummary] = useState([]);
  const [isOverAllShow, setIsOverAllShow] = useState(false);
  const [isSummaryData, setIsSummaryData] = useState([]);
  const [isDiseaseListHide, setIsDiseaseListHide] = useState(isDiseaseListShow);
  const [isTableViewShow, setIsTableViewShow] = useState(isDiseaseListShow);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isSearchVariable, setIsSearchVariable] = useState("");

  //initial clear
  useEffect(() => {
    setFilterContent({
      ...filterContent,
      source: [],
      relevance: "",
    });
  }, []);

  const fetchData = useCallback(async () => {
    if (isDiseaseListHide) {
      try {
        let diseaseTagList = await get_disease_tagged(filterContent?.userName);

        if (diseaseTagList) {
          setDiseaseTags(diseaseTagList[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  }, [isDiseaseListHide, filterContent?.userName]);

  const getFetchTableList = useCallback(async () => {
    if (isTableVisible) {
      try {
        setLoading(true);
        let diseseDataList = await get_all_disease_tagged(
          filterContent?.userName
        );
        if (diseseDataList) {
          setDiseaseTableList(diseseDataList);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      }
    }
  }, [isTableVisible, filterContent?.userName]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    getFetchTableList();
  }, [getFetchTableList]);

  useEffect(() => {
    setLoading(commonLoading);
  }, [commonLoading]);

  useEffect(() => {
    if (isDiseaseListShow) {
      setIsShowFileWithBack(false);
      setSearchInputVal("");
      setIsTableViewShow(false);
      setSelectedData([]);
      setIsDiseaseListHide(isDiseaseListShow);
      setIsSearchTableShow(false);
      setIsOverAllShow(false);
      setData([]);
    }
  }, [isDiseaseListShow]);

  useEffect(() => {
    if (!isDiseaseListShow) {
      setLoading(false);
      setIsDiseaseListHide(isDiseaseListShow);
    }
  }, [isDiseaseListShow]);

  useEffect(() => {
    if (isTableVisible) {
      setIsTableViewShow(isTableVisible);
      setData([]);
      setIsOverAllShow(false);
      setSearchInputVal("");
      setSelectedData([]);
      setIsDiseaseListHide(false);
      setIsShowFileWithBack(false);
    }
  }, [isTableVisible]);

  useEffect(() => {
    if (!isTableVisible) {
      setIsTableViewShow(isTableVisible);
      setIsShowFileWithBack(false);
      setSearchInputVal("");
      setIsSearchTableShow(false);
      setIsOverAllShow(false);
      setData([]);
    }
  }, [isTableVisible]);

  const onchangeGetValue = (val) => {
    setSearchInputVal(val);
  };

  const handleGoBackTable = () => {
    setIsTableViewShow(true);
    if (data) {
      setIsSearchTableShow(false);
      setIsOverAllShow(false);
      setData([]);
    }
  };

  //similar Paper
  const SimilarPaper = async () => {
    let id = [].concat(...selectedData.map((item) => item.article_ids));

    if (id.length > 0) {
      let body = {
        id_list: id,
        searchvariable: isSearchVariable,
      };
      setIsOverAllShow(false);
      setLoading(true);
      let referenceResult = await referencePapers(body);
      if (referenceResult && Object.keys(referenceResult).length !== 0) {
        setIsSummaryData(referenceResult?.articles);
        let res1 = referenceResult?.articles.map((e) => ({
          id: e.PMID,
          Article_title:
            typeof e.Article_title === "string"
              ? e.Article_title
              : e.Article_title["#text"],
          Summary: e.Summary.summary,
          keyword: e.Summary.keywords,
          article_ids: e.article_ids,
          pdf_url: e.download_pdf_url ? e.download_pdf_url : "",
        }));

        if (referenceResult?.overall_summary) {
          let { overall_summary } = referenceResult;
          if (overall_summary) {
            setIsOverAllSummary(overall_summary);
            setIsOverAllShow(true);
          }
        }
        if (referenceResult?.searchvariable) {
          setIsSearchVariable(referenceResult?.searchvariable);
        }
        setLoading(false);
        setSelectedData([]);
        setCheckedKeys([]);
        setData(res1);
      } else {
        setCheckedKeys([]);
        setLoading(false);
      }
    } else {
      toast.error("No similar papers");
    }
  };

  const renderAction = (pdf_url) => {
    return (
      <div className="actionCell">
        {pdf_url.length > 0 ? (
          <>
            <div>
              <a href={pdf_url[0]} target="_blank" rel="noopener noreferrer">
                <img src={shareIcon} alt="pubmed" className="shareIconPubmed" />
              </a>
            </div>
            <CopyToClipboard text={pdf_url[0]}>
              <MdContentCopy
                size={25}
                className="iconHover outlineNone"
                data-tooltip-content="Copy link"
                data-tooltip-id="my-tooltip"
                onClick={() => toast.success("Url Copied")}
              />
            </CopyToClipboard>
          </>
        ) : (
          "No Reference Found"
        )}
      </div>
    );
  };
  const fetchDataWithRetries = async (maxRetries, body) => {
    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const response = await pubmedSearch(body);

        if (response !== "Retry") {
          return response;
        } else {
          console.log(`Attempt ${attempt + 1} failed. Retrying...`);
        }
      } catch (error) {
        console.error(`Error in attempt ${attempt + 1}:`, error);
      }
    }
  };

  const handleCheckboxChange = (itemId) => {
    if (itemId === "selectAll") {
      const allCheckboxIds = data.map((item) => item.id);
      if (selectedCheckboxes.length === allCheckboxIds.length) {
        setSelectedCheckboxes([]);
        setSelectedData([]);
      } else {
        isCheckArticles(data);
        setSelectedCheckboxes(allCheckboxIds);
        setSelectedData(data);
      }
    } else {
      if (selectedCheckboxes.includes(itemId)) {
        setSelectedCheckboxes(selectedCheckboxes.filter((id) => id !== itemId));
        let removeData = selectedData.filter(
          (filterData) => filterData.id !== itemId
        );

        isCheckArticles(removeData);
        setSelectedData(removeData);
      } else {
        setSelectedCheckboxes([...selectedCheckboxes, itemId]);
        let filterData = data.filter((filterData) => filterData.id === itemId);
        let mergeSelectData = [...selectedData, ...filterData];
        isCheckArticles(mergeSelectData);
        setSelectedData((prev) => [...prev, ...filterData]);
      }
    }
  };

  const isCheckArticles = (getData) => {
    // Check if any article has article_ids
    const hasArticleIds = getData.some(
      (article) => article.article_ids.length > 0
    );
    if (hasArticleIds) {
      setButtonDisabled(false);
    } else {
      setButtonDisabled(true);
    }
  };

  // handleSubmit
  const handleSubmit = async (e) => {
    if (searchInputVal) {
      e.preventDefault();
      setSelectedData([]);
      setLoading(true);
      let body = {
        search_term: searchInputVal,
        filters:
          filterContent.source.length > 0 ? filterContent.source : ["string"],
        date_filters: filterContent.relevance
          ? filterContent.relevance
          : "string",
        email: sessionStorage.getItem("email"),
      };

      setIsTableViewShow(false);
      setIsOverAllShow(false);

      setLoading(true);
      let getSearchRes = await fetchDataWithRetries(2, body);

      if (getSearchRes && Object.keys(getSearchRes).length !== 0) {
        if (getSearchRes?.searchvariable) {
          setIsSearchVariable(getSearchRes?.searchvariable);
        }

        if (getSearchRes?.overall_summary) {
          let { overall_summary } = getSearchRes;
          if (overall_summary) {
            setIsOverAllSummary(overall_summary);
            setIsOverAllShow(true);
          }
        }
        setIsSummaryData(getSearchRes?.articles);
        let res1 = getSearchRes?.articles.map(
          ({
            PMID,
            Article_title,
            Summary,
            article_ids,
            download_pdf_url,
          }) => ({
            id: PMID,
            Article_title:
              typeof Article_title === "string"
                ? Article_title
                : Article_title["#text"],
            Summary: Summary.summary,
            keyword:
              Summary.keywords === undefined
                ? Summary.Keywords
                : Summary.keywords,
            article_ids,
            pdf_url: download_pdf_url || "",
          })
        );
        setSelectedCheckboxes([]);
        setSelectedData([]);
        setCheckedKeys([]);
        setData(res1);
        setLoading(false);
        setIsSearchTableShow(true);
      } else {
        setLoading();
        toast.error("Please try some other queries");
        setIsTableViewShow(true);
      }
    } else {
      toast.error("Please Enter An Term");
    }
  };

  const handleClickName = (data) => {
    setDiseaseTags(data);
    setIsShowFileWithBack(true);
    setSearchInputVal("");
  };

  const handleToDeleteS3Pub = async (val) => {
    if (val) {
      const { file_name } = val;
      let payload = {
        first_name: filterContent?.userName,
        filename: file_name,
      };

      let deleteResp = await delete_file_s3_pub(payload);
      if (deleteResp.status === 200) {
        const { file_name } = val;
        let payload = {
          first_name: filterContent?.userName,
          filename: file_name,
        };
        let deleteStatus = await delete_disease_tagged_db(payload);

        if (deleteStatus.status === 200) {
          if (diseaseTableList.length > 1) {
            if (file_name !== diseaseTags.file_name) {
              getFetchTableList();
            } else {
              getFetchTableList();
              setIsShowFileWithBack(false);
              setSearchInputVal("");
            }
          } else {
            getFetchTableList();
            setIsShowFileWithBack(false);
            setSearchInputVal("");
          }
        }
      }
    }
  };

  const handlSummarise = async () => {
    setIsOverAllShow(false);

    let flatConvert = selectedData;
    let filteredData = isSummaryData.filter((originalItem) => {
      return flatConvert.some(
        (selectedItem) => selectedItem.id === originalItem.PMID
      );
    });

    let payload = {
      output_list: filteredData,
      searchvariable: isSearchVariable,
    };
    setLoading(true);
    let getSummary = await pubmedSearchSummary(payload);
    if (getSummary) {
      setIsOverAllSummary(getSummary?.overall_summary);
      setIsOverAllShow(true);
      setLoading(false);
    } else {
      setLoading(false);
      setIsOverAllShow(true);
    }
  };

  const handleNewChat = async () => {
    setLoading(true);
    let payload = {
      email: sessionStorage.getItem("email"),
    };
    let getSessionStatus = await new_chat_session(payload);

    if (getSessionStatus.status === 200) {
      setIsOverAllShow(false);
      setIsTableViewShow(false);
      setIsShowFileWithBack(false);
      setSearchInputVal("");
      setIsSearchTableShow(false);
      setIsOverAllShow(false);
      setData([]);
      setSelectedData([]);
      setSelectedCheckboxes([]);
      setFilterContent({ ...filterContent, source: [], relevance: "" });

      toast.success(getSessionStatus?.data?.Message);
    }
    setLoading(false);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && searchInputVal.trim() !== "") {
      handleSubmit(event);
    }
  };

  const CheckBoxItem = ({ id, isChecked, onChange }) => {
    return (
      <input
        type="checkbox"
        checked={isChecked}
        onChange={() => onChange(id)}
      />
    );
  };

  const isAllChecked = selectedCheckboxes.length === data?.length;

  return (
    <div className="d-flex justify-content-between postionRelative">
      <div className="mainContent w-100">
        {Loading && (
          <CommonLoader
            visible={Loading}
            customLoaderClass="loaderCenter bgwhite"
          />
        )}

        {data.length > 0 ||
        selectedData.length > 0 ||
        (isTableViewShow && diseaseTableList.length > 0) ||
        diseaseTags.length > 0 ? (
          <div className="pubmedPrompt margin15px">
            <div className="diseaseNameMain">
              {isDiseaseListHide && diseaseTags && (
                <DiseaseNameList
                  getData={diseaseTags}
                  onchangeGetValue={onchangeGetValue}
                />
              )}
            </div>

            <div className="diseaseNameMain">
              {isShowFileWithBack && diseaseTags && (
                <DiseaseNameList
                  getData={diseaseTags}
                  onchangeGetValue={onchangeGetValue}
                  isShowGoBack
                  handleGoBack={handleGoBackTable}
                />
              )}
            </div>

            {isOverAllShow &&
              isOverAllSummary &&
              isOverAllSummary.length > 0 && (
                <>
                  <div className="overAllSummaryWrap">
                    <p className="overAllTitle">{commonStr.summary}</p>
                    <p className="overAllDes">{isOverAllSummary}</p>
                  </div>
                </>
              )}

            <div
              className={`summarizeBtnWrap ${
                selectedData && selectedData.length > 0 && "summarizeBtnFixed"
              }`}
            >
              {selectedData && selectedData.length > 0 && (
                <CommonBtn
                  title={commonStr.generateSummary}
                  className="outlineBtn mr-3 iconHover "
                  onClick={handlSummarise}
                  outlineBtn
                />
              )}
              {selectedData && selectedData.length > 0 && (
                <CommonBtn
                  title={commonStr.showSimilar}
                  className={`outlineBtn ${
                    isButtonDisabled && "outlineBtnDisable"
                  }`}
                  onClick={SimilarPaper}
                  disabled={isButtonDisabled}
                  outlineBtn
                />
              )}
            </div>

            <div>
              {isTableViewShow && diseaseTableList.length > 0 && (
                <DiseaseTable
                  getClickName={handleClickName}
                  tableData={diseaseTableList}
                  getClickDelete={handleToDeleteS3Pub}
                />
              )}
            </div>

            {Loading === false && isSearchTableShow && data?.length > 0 && (
              <div className="customeTable  ">
                <div
                  className={`row customTableHeader ${
                    selectedData && selectedData.length > 0
                      ? "top43px"
                      : "top2px"
                  }`}
                >
                  <div className="col-2">
                    <div className="row">
                      <div className="col-2 p-0">
                        <CheckBoxItem
                          id="selectAll"
                          label="Select All"
                          isChecked={isAllChecked}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                      <div className="col-9 pl-0">
                        <p>Article Id</p>
                      </div>
                    </div>
                  </div>

                  <div className="col-3">
                    <p>Article Name</p>
                  </div>
                  <div className="col-5">
                    <p>Summary</p>
                  </div>
                  <div className="col-2 text-center">
                    <p>Source</p>
                  </div>
                </div>

                {data?.map((item, id) => {
                  return (
                    <div key={id} className="customTableCard">
                      <div className="row padding10">
                        <div className="col-12">
                          <div className="row  ">
                            <div className="col-2">
                              <div className="row">
                                <div className="col-2">
                                  <CheckBoxItem
                                    key={item.id}
                                    id={item.id}
                                    label={item.Article_title}
                                    isChecked={selectedCheckboxes.includes(
                                      item.id
                                    )}
                                    onChange={handleCheckboxChange}
                                  />
                                </div>
                                <div
                                  className="col-9 marginTop2px"
                                  data-tooltip-content={item.id}
                                  data-tooltip-id="article"
                                >
                                  <p className="fontBoldTitle">{item.id}</p>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-3"
                              data-tooltip-content={item.Article_title}
                              data-tooltip-id="article"
                            >
                              <p className="fontBoldTitle">
                                {item.Article_title}
                              </p>
                            </div>
                            <div
                              className="col-5 customSummarySection"
                              data-tooltip-content={item.Summary}
                              data-tooltip-id="my-tooltip"
                            >
                              <p className="fontSize0-9 descriptionTable">
                                {item?.Summary && item?.Summary?.length > 0
                                  ? item?.Summary.length > 250
                                    ? item?.Summary.slice(0, 250) + "..."
                                    : item?.Summary
                                  : "No Abstract Found"}
                              </p>
                            </div>
                            <div className="col-2 referenceContainer">
                              {renderAction(item.pdf_url)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="keywordSection">
                        <div className="keywordsTitle">
                          {item?.keyword &&
                            Object.keys(item.keyword).length > 0 && (
                              <h6>Keywords : </h6>
                            )}
                        </div>
                        <div className="keywordMain">
                          <div className="kewordsListWrap">
                            {Object.keys(item?.keyword).map((key, index) => (
                              <div
                                className="keywordsContainer"
                                data-tooltip-content={item?.keyword[key]}
                                data-tooltip-id="my-tooltip"
                                key={index}
                              >
                                {key}
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        ) : (
          <ChatBot userName={filterContent?.userName} />
        )}

        <div className="mx-1 searchInputZindex">
          <SearchInputArrowBtn
            value={searchInputVal}
            placeholder={commonStr.typeYourQuery}
            onKeyPress={handleKeyPress}
            onChange={(e) => setSearchInputVal(e.target.value)}
            inputValue={searchInputVal}
            onClick={handleSubmit}
            audioHide={false}
            isNewChatShow
            handleNewChat={handleNewChat}
            customSerchInputWrap="pubmedInputContainer"
            inputWidth={
              selectedData && selectedData.length > 0 ? "width88" : "width100"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default PubmedQuery;
