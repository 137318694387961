import React, { useContext, useEffect, useState } from "react";
import Promptpage from "./Promptpage";
import ExtractedFilesPage from "./ExtractedFilesPage";
import "./CommonPromptFilePage.css";
import { MyContext } from "../../context/ContextApi";
import { extractedFilesData } from "../../api/Postaction";

function CommonPromptFilePage() {
  const { fileNameExtract } = useContext(MyContext);
  const email = sessionStorage.getItem("firstname");

  const [loading, setLoading] = useState(false);
  const [dataExtract, setDataExtract] = useState()

  useEffect(() => {
    setLoading(true);
    let body = {
      user_name: email,
      filename: fileNameExtract,
    };
    const fetchExtractedData = async () => {
      try {
        let res = await extractedFilesData(body);
        setLoading(false);
        setDataExtract(res)
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    };
    fetchExtractedData();
  }, []);

  return (
    <div className="commonPromptFilePage-header">
      <div className="promptPage">
        <Promptpage />
      </div>

      <div className="vertical-line" />

      <div
        className="extractedFiles_page"
        // style={{
        //   flex: 1,
        //   border: "1px solid #ccc",
        //   borderRadius: "8px",
        //   boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
        //   padding: "20px",
        //   textAlign: "center",
        //   backgroundColor: "#fff",
        //   margin: "10px",
        //   overflowY: 'auto',
        // }}
      >
        <ExtractedFilesPage loading={loading} dataExtract={dataExtract}/>
      </div>
    </div>
  );
}

export default CommonPromptFilePage;
