import React, { useState, useContext } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import { aglogo, uploadingDoc, threeDots } from "../../utils/images";
import { useNavigate } from "react-router-dom";
import { uploadAudioCallInsightsData } from "../../api/Postaction";
import { Modal } from "react-bootstrap";
import { MyContext } from "../../context/ContextApi";

function UploadCallinsights() {
  const [loading, setLoading] = useState(false);

  const { setFileNameExtract, fileName, setFileName } = useContext(MyContext);

  const name = sessionStorage.getItem("firstname");

  const navigate = useNavigate();

  const handleBrowseFilesClick = () => {
    const file_upload = document.getElementById("file_upload");
    file_upload.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setFileName(file.name);
    let body = {
      name: name,
      file: file,
    };
    setLoading(true);
    try {
      let res = await uploadAudioCallInsightsData(body);

      setFileNameExtract(res?.file_name);
      if (res?.file_name.length > 0) {
        setLoading(false);
        navigate("/promptExtractFilesPage");
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  return (
    <div style={{ marginTop: "25vh" }}>
      <div style={{ textAlign: "center", marginBottom: "25px" }}>
        <img
          style={{ width: "75px", height: "75px" }}
          src={aglogo}
          alt="Ag_logo"
        />
      </div>
      <div
        style={{
          border: "2px dashed #d3d3d3",
          borderRadius: "8px",
          padding: "40px",
          textAlign: "center",
          maxWidth: "450px",
          margin: "auto",
          color: "#333",
        }}
      >
        <p
          style={{
            marginBottom: "10px",
            fontSize: "20px",
            fontWeight: "500",
          }}
        >
          Upload Document
        </p>

        <div
          style={{
            fontSize: "30px",
            color: "#1a73e8",
            marginBottom: "10px",
          }}
        >
          <MdOutlineFileUpload />
        </div>
        <div
          style={{
            display: "inline-flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <span
            htmlFor="file_upload"
            style={{
              color: "#1a73e8",
              cursor: "pointer",
            }}
            onClick={handleBrowseFilesClick}
          >
            Click to Upload
          </span>
          <input
            id="file_upload"
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept=".mp3, .m4a, .wav, .aac, .flac"
          />
          <span style={{ color: "#888" }}>or drag and drop</span>
        </div>
        <p style={{ color: "#a3a3a3", fontSize: "12px" }}>
          (Max. File size: 3MB)
        </p>
        <div style={{ marginTop: "10px", color: "#555" }}>
          {fileName ? (
            <>
              Selected File: <span style={{ color: "green" }}>{fileName}</span>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
      {loading && (
        <Modal
          show={loading}
          centered
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          }}
        >
          <Modal.Body
            style={{
              textAlign: "center",
              padding: "20px",
              width: "500px",
              height: "150px",
              backgroundColor: "white",
              borderRadius: "8px",
            }}
          >
            <img
              src={uploadingDoc}
              alt="Uploading"
              style={{ width: "45px", height: "45px", marginTop: "15px" }}
            />
            <div style={{ marginTop: "10px", fontSize: "16px" }}>
              Uploading. Please wait
              <img
                src={threeDots}
                alt="..."
                style={{
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                }}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default UploadCallinsights;
