import React, { useContext, useEffect, useState } from "react";
import { Table, Pagination } from "rsuite";
import Modal from "react-bootstrap/Modal";
import edit from "../../assets/svg/edit.svg";
import delete1 from "../../assets/svg/delete.svg";
import { useController, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { createCollectionValidaton } from "../../validation";
import { getUrl } from "../../api/Endpoint";
import {
  createCollections,
  deleteCollections,
  deleteDocumentAll,
} from "../../api/Postaction";
import EditCollection from "./editCollection";
import { MyContext } from "../../context/ContextApi";
import axiosApi from "../../interceptors/axiosinterceptor";
import { CommonBtn, CommonModal, SearchInputBtn } from "../../common";
import { commonStr } from "../../utils/constants/commonStrings";
import Select from "react-select";
import { deleteMultipleCollection } from "../../api/DeleteAction";

const Collection = () => {
  const { Column, HeaderCell, Cell } = Table;
  const { setCollectionfetch } = useContext(MyContext);
  const [modalShow, setModalShow] = useState(false);
  const [fetch, setFetch] = useState(false);
  const [search, setSearch] = useState("");
  const [Loading, setLoading] = useState(true);
  const [records, setRecords] = useState();
  const [data, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState();
  const [deleteData, setDeleteData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editmodalShow, setEditModalShow] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [delMulModalShow, setDelMulModalShow] = useState();

  const email = sessionStorage.getItem("email");

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: zodResolver(createCollectionValidaton),
    mode: "all",
  });

  const SolutionAccess = useController({
    name: "SolutionAccess",
    control,
  });

  const SolutionOptions = [
    { value: "Doc Sonar", label: "Doc Sonar" },
    { value: "Call Insights", label: "Call Insights" },
  ];

  const handleSolutionAccess = (option) => {
    SolutionAccess.field.onChange(option);
  };

  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
    if (search !== "") {
      setSearch("");
    }
  };
  const CollectionList = async () => {
    setLoading(true);
    try {
      let result = await axiosApi.get(`${getUrl.listCollection}`);
      setData(result.data);
      setLoading(false);
    } catch (err) {
      console.log("Error in CollectionList", err);
    }
  };

  useEffect(() => {
    CollectionList();
  }, [fetch]);

  useEffect(() => {
    if (editData !== undefined) {
      setEditModalShow(true);
    }
  }, [editData]);

  // useEffect(() => {
  //   if (deleteData !== undefined) {
  //     setDeleteModal(true);
  //   }
  // }, [deleteData]);

  const closePerson = async () => {
    setDeleteModal(false);
    try {
      let payload = {
        collection_name: deleteData.collection_name,
      };

      let body = {
        email: email,
        collection_name: deleteData.collection_name,
      };

      await deleteCollections(payload, setFetch);
      await deleteDocumentAll(body);
      setDeleteModal(false);
    } catch (err) {
      console.error("Error in closePerson:", err);
    }
  };

  useEffect(() => {
    if (search !== "") {
      // const start = limit * (page - 1);
      // const end = start + limit;
      const result = data?.filter((data) => {
        return (
          data?.collection_name?.toLowerCase().indexOf(search?.toLowerCase()) >=
          0
        );
      });
      setRecords(result);
    } else {
      const res = data?.filter((v, i) => {
        const start = limit * (page - 1);
        const end = start + limit;
        return i >= start && i < end;
      });
      setRecords(res);
      // setLimit(result.length);
    }
  }, [search]);

  useEffect(() => {
    const res = data?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
    setRecords(res);
    // }
  }, [data, page, limit]);

  const createCollection = async (formValues) => {
    reset();
    setModalShow(false);
    let body = {
      collection_name: formValues.collectionname,
      bucket: formValues.bucketname,
      folder: formValues.collectionname,
      solution_name: formValues.SolutionAccess.value,
      last_used_by: email,
      created_by: email,
    };
    setLoading(true);
    await createCollections(body, setFetch);
    setCollectionfetch((e) => !e);
    setLoading(false);
  };

  const handleClickAdd = () => {
    reset();
    setModalShow(true);
  };
  const handleClickDel = () => {
    reset();
    setDelMulModalShow(true);
  };

  const handleDeleteCloseModal = () => {
    setDeleteModal(false);
  };

  const handleClickDeleteIcon = (rowData) => {
    setDeleteData(rowData);
    setDeleteModal(true);
  };

  const handleChecked = (rowData) => {
    setSelectedRows((prevSelectedRows) => {
      const isAlreadySelected = prevSelectedRows?.some(
        (row) => row?.collection_name === rowData?.collection_name
      );
      if (isAlreadySelected) {
        return prevSelectedRows.filter(
          (row) => row.collection_name !== rowData?.collection_name
        );
      } else {
        return [...prevSelectedRows, rowData];
      }
    });
  };

  const deleteMulCollection = async () => {
    try {
      setDelMulModalShow(false);
      setLoading(true);
  
      if (Array.isArray(selectedRows) && selectedRows.length > 0) {
        const body = selectedRows?.map((val) => val?.collection_name);

        await deleteMultipleCollection(body);
        setLoading(false);
        setCollectionfetch((prev) => !prev);
        CollectionList()
      } else {
        console.error("selectedRows is empty or not an array:", selectedRows);
      }
    } catch (error) {
      setLoading(false)
      console.error("Error deleting collections:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      <SearchInputBtn
        placeholder={`${commonStr?.search}  ${commonStr?.collection}`}
        btnTitle={`${commonStr?.add}  ${commonStr?.collection}`}
        btnDelTitle={`${commonStr?.delete} ${commonStr?.collection}`}
        btnOnClick={handleClickAdd}
        btnOnDelClick={handleClickDel}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        selectedRows={selectedRows}
      />

      <div className="mt-4">
        <div>
          <Table
            loading={Loading ? true : false}
            wordWrap="break-word"
            className="custom-table"
            virtualized
            hover={false}
            height={434}
            data={records}
            style={{
              scrollbarWidth: "0",
              maxHeight: "58vh",
            }}
            align="center"
          >
            <Column width={330} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Collection Name</HeaderCell>
              <Cell dataKey="collection_name" id="content" />
            </Column>

            <Column width={410} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Bucket</HeaderCell>
              <Cell dataKey="bucket" id="content" />
            </Column>
            <Column width={410} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Solution Name</HeaderCell>
              <Cell dataKey="solution_name" id="content" />
            </Column>
            <Column width={410} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Created By</HeaderCell>
              <Cell dataKey="created_by" id="content" />
            </Column>
            <Column width={410} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Created Date</HeaderCell>
              <Cell dataKey="created_date" id="content" />
            </Column>
            <Column width={410} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Last Used By</HeaderCell>
              <Cell dataKey="last_used_by" id="content" />
            </Column>
            <Column width={410} flexGrow={2} verticalAlign="middle">
              <HeaderCell id="header">Last Used Date</HeaderCell>
              <Cell dataKey="last_used_date" id="content" />
            </Column>
            <Column width={240} flexGrow={1} verticalAlign="middle">
              <HeaderCell id="header" style={{ minWidth: "160px" }}>
                Action
              </HeaderCell>
              <Cell style={{ padding: "6px", marginLeft: "6px" }} id="content">
                {(rowData) => (
                  <>
                    <img
                      src={edit}
                      alt="edit"
                      onClick={() => setEditData(rowData)}
                      style={{ cursor: "pointer" }}
                    />
                    &ensp;
                    <img
                      src={delete1}
                      alt="edit"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClickDeleteIcon(rowData)}
                    />
                    &ensp;
                    <input
                      type="checkbox"
                      style={{ cursor: "pointer" }}
                      onChange={() => handleChecked(rowData)}
                      checked={selectedRows?.some(
                        (row) => row.collection_name === rowData.collection_name
                      )}
                    />
                  </>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ paddingTop: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="xs"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={search === "" ? data.length : records.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          {" "}
          <form onSubmit={handleSubmit(createCollection)}>
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                Add Collection
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input
                type="text"
                className="w-100 form-control"
                {...register("collectionname")}
                style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
                placeholder="Collection Name"
              />
              <div style={{ color: "red", height: "10px" }}>
                {errors.collectionname?.message}
              </div>
              <input
                type="text"
                className="w-100 mt-3 form-control"
                {...register("bucketname")}
                style={{ height: "38px", padding: "10px", borderRadius: "5px" }}
                placeholder="Bucket Name"
              />
              <div style={{ color: "red", height: "10px" }}>
                {errors.bucketname?.message}
              </div>
              <Select
                className="mt-3"
                placeholder="Select Solution"
                onChange={handleSolutionAccess}
                value={SolutionAccess.field.value}
                options={SolutionOptions}
              />

              <div style={{ color: "red", height: "10px" }}>
                {errors.SolutionAccess?.message}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <CommonBtn
                title={commonStr.cancel}
                onClick={() => setModalShow(false)}
                className="mr-2"
              />
              <CommonBtn type="submit" title={commonStr.add} />
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      {/* edit */}
      <div>
        <Modal
          show={delMulModalShow}
          onHide={() => setDelMulModalShow(false)}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          backdrop="static"
          keyboard={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Delete Collections
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedRows?.map((data) => (
              <ul key={data.collection_id}>
                <li>{data.collection_name}</li>
              </ul>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <CommonBtn
              title={commonStr.cancel}
              onClick={() => setDelMulModalShow(false)}
              className="mr-2"
            />
            <CommonBtn
              type="submit"
              onClick={deleteMulCollection}
              title={commonStr.delete}
            />
          </Modal.Footer>
        </Modal>
      </div>

      <EditCollection
        editmodalShow={editmodalShow}
        setEditModalShow={setEditModalShow}
        editData={editData}
        setEditData={setEditData}
        setCollectionfetch={setCollectionfetch}
        setFetch={setFetch}
      />

      {deleteModal && (
        <CommonModal
          showModal={deleteModal}
          title={"Delete"}
          content={`Are you sure want to delete this  "${deleteData?.collection_name}"  Collection ?`}
          onCancel={handleDeleteCloseModal}
          onConfirm={closePerson}
        />
      )}
    </div>
  );
};

export default Collection;
