import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useMsal } from "@azure/msal-react";
import { EventType } from "@azure/msal-browser";
import { loginRequest } from "./authProvider";
import { userList } from "../../api/GetActions";
import { commonStr } from "../../utils/constants/commonStrings";
import { errorStr } from "../../utils/constants/errorStrings";
import "./loginpage.scss";
import { sendTokenApi } from "../../api/Postaction";
import { showToast } from "../../utils/helpers/helpers";
import { STATUS_CODE } from "../../utils/constants/constantData";

const LoginPage = ({ setLoggedin, isSessionExpired }) => {
  const navigate = useNavigate();
  const { instance, accounts } = useMsal();

  useEffect(() => {
    // Clear session storage when component mounts
    sessionStorage.clear();
  }, []);

  const handleLogin = async () => {
    try {
      const currentAccounts = instance.getAllAccounts();
      if (currentAccounts && currentAccounts.length > 0) {
        // User is already logged in, no need to login again
        return;
      }
      const loginResponse = await instance.loginPopup(loginRequest);

      if (loginResponse && loginResponse.account) {
        const accessToken = loginResponse.accessToken;
        sessionStorage.setItem("accessToken", accessToken);
        const email = loginResponse.account.username;
        handleTokenAndUser(accessToken, email);
      } else {
        // SSO login failed
        showToast(errorStr.loginFail);
      }
    } catch (error) {
      // console.error("Login error:", error);
    }
  };

  const handleTokenAndUser = async (accessToken, email) => {
    try {
      // Make a POST request to pass the token to the backend

      let payload = {
        token: accessToken,
      };
      const response = await sendTokenApi(payload);
      if (response?.status === STATUS_CODE.OK) {
        const { message } = response?.data;
        if (message) {
          sessionStorage.setItem("email", email);
          navigate("/newquery");
          showToast(commonStr.userAuthienticated, 1);
        }
        let matchingUser;

        const userListResponse = await userList();
        if (userListResponse) {

        const users = userListResponse;
        const normalizedUsername = email.toLowerCase();

          matchingUser = users.find( 
            (user) => user.email.toLowerCase() === normalizedUsername
          ); 
        } else { 
          showToast(errorStr.anError);
        }
        if (matchingUser) {
          const activeAccount = instance.getActiveAccount();
          if (
            activeAccount &&
            activeAccount.idToken &&
            activeAccount.idToken.exp < Date.now() / 1000
          ) {
            showToast(errorStr.tokenExpired);
            navigate("/"); 
            sessionStorage.clear();
            return;
          }
           sessionStorage.setItem("firstname", matchingUser.firstname);
           sessionStorage.setItem("email", matchingUser.email);
           sessionStorage.setItem("admin", matchingUser.is_admin);
           sessionStorage.setItem("userID", matchingUser.user_id);
           setLoggedin((current) => !current);
           navigate("/newquery");
         
        } else {
          navigate("/");
        }
      } else {
        showToast(errorStr.tokenInvalid);
        navigate("/");
      }
    } catch (error) {
      console.error(
        "Error sending token to backend or fetching user list:",
        error
      );
      showToast(errorStr.anError);
    }
  };

  useEffect(() => {
    if (isSessionExpired || !accounts || accounts.length === 0) {
      handleLogin();
    }

  const handleLogoutEvent = (event) => {
      if (
        event instanceof Event &&
        event.eventType === EventType.LOGOUT_SUCCESS
      ) {
        console.log("Logout event occurred.");
      }
    };

  instance.addEventCallback(handleLogoutEvent);

    return () => {
      instance.removeEventCallback(handleLogoutEvent);
    };
  }, [instance, accounts, isSessionExpired, setLoggedin]);

  return (
    <div className="login">
      <div className="login__bgwrap">
        <p className="login__bgwrap__title">{commonStr.welcomeTo}</p>
        <h3 className="login__bgwrap__header">{commonStr.genIn}</h3>
        <button onClick={handleLogin} className="login__bgwrap__gradient-btn">
          {commonStr.signInWith} {commonStr.genIn}
        </button>
      </div>
    </div>
  );
};


export default LoginPage; 
