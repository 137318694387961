export const commonStr = {
  //login
  welcome: "Welcome Back to",
  genIn: "Gen-Insights",
  passoword: "Password",
  rememberMe: "Remember me",
  signIn: "Sign In",
  userName: "Username",
  signInWith: "Sign in with",
  welcomeTo: "Welcome Back to",
  //sidebar
  logoutTitle: "Logout",
  areYouWant: "Are you sure you want to",
  deleteSessionTitle: "Delete Session",
  ques: "?",
  newQuery: "New Query",
  currentSession: "Current Session",
  sessionHistory: "Session History",
  noSession: "No session Available",
  clearSession: "Clear History",
  manageUsers: "Manage Users",
  help: "Help",
  logout: "Log out",
  sourceFile: "Source Filter",
  clear: "Clear",
  relevance: "Relevance Filter",
  dataSearch: "Data Lens",
  generateKpi: "Generate KPI",
  docSonar: "Doc Sonar",
  uploadNewDoc: "Upload New Document",
  dashLens: "Dash Lens",
  literature: "Literature AI",
  generator: "Content Generation AI",
  translator: "HCP MLT",
  medical: "MLR Review",
  textToChart: "Dash Vista",
  clickMenu: "Select search type",
  selectKpi: "Select KPI(s)",
  enterRecipient: "Enter Recipient",
  cancel: "Cancel",
  sendMail: "Send Mail",
  upload: "Upload",
  document: "Document",
  uploadDoc: "Upload Document :",
  link: "Link",
  or: "or",
  localPath: "Local Path",
  hello: "Hello",
  howCan: "How can I help you today ?",
  fileList: "File Lists",
  omnilens: 'Omni Lens',
  newSession: 'New Session',
  sentimentAnalysis: "Sentiment Analysis",
  emergencySolution: "Sales Rep Co-pilot",
  audioLogging: "Audio Logging",

  //commentBtn
  generating: "Generating...",
  searching: "Searching",
  thinking: "Thinking...",

  //searchInput
  typeYourQuery: "Type your query here",
  feedback: "Feedback",
  share: "Share Detail in Whatsapp",
  send: "Send",
  mail: "Mail",
  notes: "Notes",
  number: "Number",
  receiptEmail: "Recipient Email(s)",
  confirm: "confirm",

  summarize: "summarize",
  newChat: "New Chat",
  search: "Search",
  showSimilar: " Show Similar Papers",
  summarise: "Summarise",
  searchHere: "Search here...",
  summary: "Summary",
  generateSummary: "Generate Summary",
  clearAllHistory: "Clear All History",
  generateAudio: "Generating audio...",
  publicationType: "No. of Publications",
  sortBy: "Sort by",
  uploadGeneList: "Upload Gene List",
  step1GeneList: "Step 1: Enter gene list",
  step2Disease: "Step 2: Enter Disease",
  optional: "(optional)",
  uploadFile: "Upload File",
  createPipeline: "Create Your PipeLine",
  typeTags: "Enter Your Tags",
  relavanceFilter: "Relevance filter",

  export: "Export",
  csv: "csv",
  tsv: "tsv",

  userAuthienticated: "User Authenticated Successfully",
  sources: "Sources",
  noSourceFile: "No Source File",

  add: "Add",
  user: "User",
  update: "Update",
  persona: "Persona",
  close: "close",
  delete: "Delete",
  collection: "Collection",
  project: "Project",
  workbook: "WorkBook",
  details: "Details",
  colections: "Collections",
  schedule: "Schedule",
  back: "Back",
  kpi: "KPI",
  action: "Action",
  edit: "Edit",
  select: "Select",
  is: "is",
  required: "Required",
  dataDetective: "Data Detective",
  commerical: "Commercial",
};
