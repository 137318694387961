import React from "react";
import { commonStr } from "../../utils/constants/commonStrings";
import { groupUserIcon } from "../../utils/images";
import { useLocation } from "react-router-dom";
import "./styles.scss";

function SidebarManagerItem({ onClick }) {

   const location = useLocation();
   const specialRoutes = [
     "/documentGeneration",
     "/documentStructure",
     "/documentHeading",
     "/documentEditor",
   ];
   const isSpecialRoute = specialRoutes.includes(location.pathname);

   const sidebarClass = isSpecialRoute
     ? "sidebar-manger-special"
     : "sidebar-manger";

  return (
    <div
      className={`${sidebarClass} mt-2 px-2 py-3 iconHover`}
      onClick={onClick}
    >
      <div className={`${sidebarClass}__imgwrap`}>
        <img src={groupUserIcon} alt="sidebar" />
        &ensp;
        <p className="font500">{commonStr.manageUsers}</p>
      </div>
    </div>
  );
}

export default SidebarManagerItem;
