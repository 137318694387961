import React from "react";
import { Modal } from "react-bootstrap";
import { commonStr } from "../../utils/constants/commonStrings";
import "./styles.scss";
import CommonBtn from "../commonBtn";
import Plot from "react-plotly.js";

const CommonModal = ({
  showModal,
  closeModal,
  title,
  content,
  onCancel,
  onConfirm,
  onCancelTitle = commonStr.cancel,
  onConfirmTitle = commonStr.confirm,
  listitems = "",
  confirmHide = true,
  evaluateData = [],
  evaluateShow = false,
  dataDetShow = false,
  dataDetPlotShow = false,
  dataDettableShow = false,
  dataList = [],
  tableList = [],
}) => {

  const parsedData =
    dataList && typeof dataList === "string" && dataList.startsWith("{")
      ? (() => {
          try {
            return JSON.parse(dataList);
          } catch (error) {
            console.error("Failed to parse dataList as JSON:", error);
            return null; 
          }
        })()
      : null;

  return (
    <Modal
      show={showModal}
      size={dataDetShow || dataDetPlotShow || dataDettableShow ? "lg" : "md"}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className={`modal__list-content ${
          (evaluateShow || dataDetShow) && `modal__list-content--height`
        }`}
      >
        {evaluateShow ? (
          <div>
            <div>
              <h6>maliciousness</h6>

              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Value :</div>
                <div className="ml-1">{evaluateData?.maliciousness?.value}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Score :</div>
                <div className="ml-1">{evaluateData?.maliciousness?.score}</div>
              </div>

              <div className="mt-2">
                <div className="modal__list-content--heading">Reasoning :</div>
                <div className="modal__list-content__reasoning">
                  {evaluateData?.maliciousness?.reasoning}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <h6>Harmfulness</h6>

              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Value :</div>
                <div className="ml-1">{evaluateData?.harmfulness?.value}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Score :</div>
                <div className="ml-1">{evaluateData?.harmfulness?.score}</div>
              </div>

              <div className="mt-2">
                <div className="modal__list-content--heading">Reasoning :</div>
                <div className="modal__list-content__reasoning">
                  {evaluateData?.harmfulness?.reasoning}
                </div>
              </div>
            </div>

            <div className="mt-2">
              <h6>Criminality</h6>

              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Value :</div>
                <div className="ml-1">{evaluateData?.criminality?.value}</div>
              </div>
              <div className="d-flex mt-2">
                <div className="modal__list-content--heading">Score :</div>
                <div className="ml-1">{evaluateData?.criminality?.score}</div>
              </div>

              <div className="mt-2">
                <div className="modal__list-content--heading">Reasoning :</div>
                <div className="modal__list-content__reasoning">
                  {evaluateData?.criminality?.reasoning}
                </div>
              </div>
            </div>
          </div>
        ) : dataDetShow ? (
          <div>
            {dataList &&
              dataList?.length > 0 &&
              dataList?.map((item, id) => {
                return (
                  <div key={id} className="mb-3">
                    <div className="d-flex mb-1">
                      <p className="font600 width10">Tool :</p>
                      <div className="ml-2 width90">{item?.tool}</div>
                    </div>

                    <div className="d-flex mb-1">
                      <p className="font600 width10">Input :</p>
                      <div className="ml-2 width90">{item?.tool_input}</div>
                    </div>

                    <div className="d-flex mb-1">
                      <p className="font600 width10">Log :</p>
                      <div className="ml-2 width90">{item?.log}</div>
                    </div>

                    <div className="d-flex mb-1">
                      <p className="font600 width10">Type :</p>
                      <div className="ml-2 width90">{item?.type}</div>
                    </div>

                    <div className="d-flex mb-1">
                      <p className="font600 width10">Output :</p>
                      <div className="ml-2 width90">{item?.output}</div>
                    </div>
                  </div>
                );
              })}
          </div>
        ) : dataDetPlotShow ? (
          <div className="modal__list-content modal__list-content--height">
            {parsedData && parsedData.data && parsedData.layout ? (
              <Plot
                data={parsedData.data}
                layout={{
                  ...parsedData.layout,
                  autosize: true,
                  width: "100%",
                  height: "100%",
                }}
                style={{ width: "100%", height: "100%" }}
                useResizeHandler={true}
              />
            ) : (
              <p>
                {typeof dataList === "string"
                  ? dataList
                  : "No data available to render the plot."}
              </p>
            )}
          </div>
        ) : dataDettableShow ? (
          <div
            style={{
              maxHeight: "400px", 
              maxWidth: "100%", 
              overflow: "auto", 
              border: "1px solid #ddd", 
            }}
          >
            {tableList &&
            tableList.tableList &&
            tableList.tableList.length > 0 ? (
              <table
                style={{
                  width: "max-content",
                  borderCollapse: "collapse",
                  marginBottom: "20px",
                }}
              >
                <thead>
                  <tr>
                    {Object.keys(tableList.tableList[0]).map((key, index) => (
                      <th
                        key={index}
                        style={{
                          padding: "8px",
                          border: "1px solid #ddd",
                          textAlign: "left",
                        }}
                      >
                        {key.replace(/_/g, " ").toUpperCase()}
                      </th>
                    ))}
                  </tr>
                </thead>

                <tbody>
                  {tableList.tableList.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                      {Object.keys(row).map((key, cellIndex) => (
                        <td
                          key={cellIndex}
                          style={{
                            padding: "8px",
                            border: "1px solid #ddd",
                          }}
                        >
                          {row[key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No tables available</p>
            )}
          </div>
        ) : (
          <p>
            {content}{" "}
            <span className="modal__list-content__title">
              {listitems && listitems}
            </span>
          </p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <CommonBtn title={onCancelTitle} onClick={onCancel} className="mr-2" />
        {confirmHide && (
          <CommonBtn onClick={onConfirm} title={onConfirmTitle} />
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CommonModal;
