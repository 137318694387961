import React, { useContext, useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import {
  cancel,
  threeDots,
  search,
  downArrow,
  upArrow,
} from "../../utils/images";
import { useNavigate } from "react-router";
import { Modal, Button } from "react-bootstrap";
import { downloadDataCallInsights } from "../../api/Postaction";
import { MyContext } from "../../context/ContextApi";
import "./CommonPromptFilePage.css";

function ExtractedFilesPage({ loading, dataExtract }) {
  const [expanded, setExpanded] = useState(false);
  const [closeModal, setCloseModal] = useState(false);
  const [formData, setFormData] = useState({});

  const { fileName } = useContext(MyContext);

  const user = sessionStorage.getItem("firstname");

  const navigate = useNavigate();

  useEffect(() => {
    setFormData(dataExtract?.extracted_data || {});
  }, [dataExtract]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCancelClick = () => {
    setCloseModal(true);
  };

  const handleCancelModal = () => {
    setCloseModal(false);
  };

  const handleConfirmAction = () => {
    navigate("/callInsights_2");
  };

  const handleInputChange = (title, subTitle) => (event) => {
    const newValue = event.target.value;

    setFormData((prev) => ({
      ...prev,
      [title]: {
        ...prev[title],
        [subTitle]: newValue,
      },
    }));
  };

  const handleDownload = async () => {
    const nested_dict = Object.keys(dataExtract?.extracted_data || {}).reduce(
      (acc, title) => {
        acc[title] = {
          ...dataExtract.extracted_data[title],
          ...(formData[title] || {}),
        };
        return acc;
      },
      {}
    );
    const body = { user_name: user, nested_dict };
    try {
      const res = await downloadDataCallInsights(body);

      //csv file download logic
      const url = res?.download_csv_url;

      const link = document.createElement("a");
      link.href = url;
      link.download = "extracted.csv";
      console.log("link", link);
      link.click();

      //   setFormData({});
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            display: "block",
            marginBottom: "20px",
            fontWeight: "bold",
          }}
        >
          Extracted Fields
        </span>
        <img
          style={{ width: "25px", height: "25px", cursor: "pointer" }}
          src={cancel}
          alt="cancel"
          onClick={handleCancelClick}
        />
      </div>
      <div>
        {dataExtract &&
          dataExtract?.extracted_data &&
          Object.keys(dataExtract?.extracted_data).map((title, ind) => (
            <Accordion
              style={{
                marginBottom: "20px",
                boxShadow: "4px 4px 4px 4px rgba(0, 0, 0, 0.1)",
                borderRadius: '8px'
              }}
              expanded={expanded === ind}
              onChange={handleChange(ind)}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontWeight: "bold" }}>{title}</span>
                  <img
                    src={expanded === ind ? upArrow : downArrow}
                    alt={expanded === ind ? "Collapse" : "Expand"}
                    style={{
                      marginLeft: "auto",
                      width: "20px",
                      cursor: "pointer",
                    }}
                  />
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                style={{
                  maxHeight: "50vh",
                  overflowY: "auto",
                }}
              >
                {Object.keys(dataExtract?.extracted_data[title]).map(
                  (subTitle, subInd) => (
                    <>
                      <span
                        style={{
                          fontWeight: "initial",
                          color: "grey",
                          alignItems: "flex-start",
                          display: "flex",
                        }}
                      >
                        {subTitle}
                      </span>
                      <Box
                        component="form"
                        sx={{ "& > :not(style)": { m: 1, width: "45ch" } }}
                        noValidate
                        autoComplete="off"
                      >
                        <TextField
                          value={
                            formData[title]?.[subTitle] ||
                            // dataExtract?.extracted_data[title][subTitle] ||
                            ""
                          }
                          onChange={handleInputChange(title, subTitle)}
                        />
                      </Box>
                    </>
                  )
                )}
              </AccordionDetails>
            </Accordion>
          ))}
      </div>
      <Button style={{ marginLeft: "78%" }} onClick={handleDownload}>
        Download
      </Button>
      {loading && (
        <Modal
          show={loading}
          centered
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: "0",
            left: "0",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          }}
        >
          <Modal.Body
            style={{
              textAlign: "center",
              width: "500px",
              height: "150px",
            }}
          >
            <img
              src={search}
              alt="searching"
              style={{
                width: "55px",
                height: "55px",
                padding: "10px",
                fontSize: "16px",
                marginTop: "10px",
              }}
            />
            <div>
              Extracting Fields. Please wait
              <img
                src={threeDots}
                alt="..."
                style={{ width: "30px", height: "30px", marginLeft: "5px" }}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}

      {closeModal && (
        <Modal
          show={closeModal}
          centered
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: "9999",
          }}
        >
          <Modal.Body
            style={{
              textAlign: "center",
              backgroundColor: "white",
              borderRadius: "8px",
              padding: "20px",
              position: "relative",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderBottom: "1px solid #ddd",
                  paddingBottom: "10px",
                  marginBottom: "20px",
                }}
              >
                <Typography id="modal-title" variant="h6" component="h2">
                  Session
                </Typography>
                <img
                  style={{
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                    position: "absolute",
                    right: "10px",
                  }}
                  src={cancel}
                  alt="cancel"
                  onClick={handleCancelModal}
                />
              </Box>
              <Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Are you sure you want to close this session?
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "20px",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    sx={{ padding: "10px 20px" }}
                    onClick={handleCancelModal}
                  >
                    Close
                  </Button>
                  <Button onClick={handleConfirmAction}>Yes</Button>
                </Box>
              </Box>
            </Box>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default ExtractedFilesPage;
