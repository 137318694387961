import React from "react";
import CommonInput from "../commonInput";
import CommonBtn from "../commonBtn";
import "./styles.scss";
import { useLocation } from "react-router";

function SearchInputBtn({
  placeholder,
  value,
  onChange,
  btnTitle,
  btnOnClick,
  btnDelTitle,
  btnOnDelClick,
  selectedRows,
}) {
  const location = useLocation();
  const currentPath = location.pathname.substring(1);
  const collection = currentPath.includes("collection");

  return (
    <div className="searchbtn-input">
      <CommonInput
        type="text"
        className="ml-3 search-input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        searchInput
        customInputClass="width40"
      />
      <CommonBtn title={btnTitle} onClick={btnOnClick} />
      {(collection && selectedRows.length> 0) && <CommonBtn title={btnDelTitle} onClick={btnOnDelClick} />}
    </div>
  );
}

export default SearchInputBtn;
