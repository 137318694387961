import {
  datasearch_large1,
  documentsearch_large1,
  dashboardsearch_large1,
  pubmedsearch_large1,
  langIcon,
  mlrIcon,
  product_expiry,
  omnilens,
  sentiMentAnalysis,
} from "../../utils/images";
import { salesRepoCopilot } from "../../utils/images";

const sourchFilterData = [
  {
    label: "Clinical Trial",
    value: "clinicaltrial",
  },
  {
    label: "Meta Analysis",
    value: "meta_analysis",
  },
  // {
  //   label: "Randomized Controlled Trial",
  //   value: "Randomized_Controlled_Trial",
  // },
  // {
  //   label: "Review",
  //   value: "Review",
  // },
  {
    label: "Systematic Review",
    value: "Systematic_Review",
  },
];

const relevanceData = [
  {
    label: "0 - 3 years",
    value: "0-3_years",
  },
  {
    label: "3 - 5 years",
    value: "3-5_years",
  },
  {
    label: "5 - 10 years",
    value: "5-10_years",
  },
  {
    label: "10+ years",
    value: "10_years+",
  },
];

const soryByData = [
  {
    label: "Best Match",
    value: "best_match",
  },
  {
    label: "Most Recent",
    value: "most_recent",
  },
];

const menuItems = [
  {
    imgSrc: datasearch_large1,
    alt: "datasearch_large",
    path: "/newquery",
    label: "Data Lens",
    disabled: true,
  },
  {
    imgSrc: documentsearch_large1,
    alt: "documentsearch_large",
    path: "/documentquery",
    label: "Doc Sonar",
    disabled: true,
  },
  {
    imgSrc: dashboardsearch_large1,
    alt: "dashboardsearch_large",
    path: "/dashboardquery",
    label: "Dash Lens",
    disabled: true,
  },
  {
    imgSrc: pubmedsearch_large1,
    alt: "pubmedsearch_large",
    path: "/pubmedquery",
    label: "Literature AI",
    disabled: true,
  },

  {
    imgSrc: pubmedsearch_large1,
    alt: "gene inspector",
    path: "/genInspector",
    label: "Gene Inspector",
    disabled: true,
  },
  {
    imgSrc: documentsearch_large1,
    alt: "documentsearch_large",
    path: "/contentGeneration",
    label: "Content Generation",
    disabled: true,
  },
  {
    imgSrc: documentsearch_large1,
    alt: "documentsearch_large",
    path: "/documentGeneration",
    label: "Document Generation",
    disabled: true,
  },

  {
    imgSrc: langIcon,
    alt: "pubmedsearch_large",
    path: "/fileUploading",
    label: "HCP MLT",
    disabled: true,
    class: "imgwidth",
  },
  {
    imgSrc: mlrIcon,
    alt: "mlr_large",
    path: "/mlrReview",
    label: "MLR Review",
    disabled: true,
    class: "h-45",
  },
  {
    imgSrc: mlrIcon,
    alt: "mlr_large",
    path: "/dataDetective",
    label: "Data Detective",
    disabled: true,
    class: "h-45",
  },
  {
    imgSrc: mlrIcon,
    alt: "mlr_large",
    path: "/comdataDetective",
    label: "C Data Detective",
    disabled: true,
    class: "h-45",
  },
  {
    imgSrc: pubmedsearch_large1,
    alt: "callInsights_2",
    path: "/zoom",
    label: "Call Insight",
    disabled: true,
    class: "h-45",
  },
  {
    imgSrc: product_expiry,
    alt: "productExpiryMitigation",
    path: "/productExpiry",
    label: "Product Expiry Mitigation",
    disabled: true,
    class: "h-45",
  },
  {
    imgSrc: omnilens,
    alt: "Omni Lens",
    path: "/omnilens",
    label: "Omni Lens",
    disabled: true,
    class: "h-45",
  },
  {
    imgSrc: sentiMentAnalysis,
    alt: "Sentiment Analysis",
    path: "/sentimentAnalysis",
    label: "Sentiment Analysis",
    disabled: true,
    class: "h-45",
  },
  {
    imgSrc: salesRepoCopilot,
    alt: "Sales Rep Co-pilot",
    path: "/salesRepCopilot",
    label: "Sales Rep Co-pilot",
    disabled: true,
    class: "h-45",
  },
  {
    imgSrc: pubmedsearch_large1,
    alt: "callInsights_2",
    path: "/callInsights_2",
    label: "Audio Logging",
    disabled: true,
    class: "h-45",
  },
];

const STATUS_CODE = {
  // 1xx Informational Responses
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  EARLY_HINTS: 103,

  // 2xx Success
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,

  // 3xx Redirection
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  UNUSED: 306,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,

  // 4xx Client Errors
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  IM_A_TEAPOT: 418,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  TOO_EARLY: 425,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TOO_MANY_REQUESTS: 429,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,

  // 5xx Server Errors
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
};

export { sourchFilterData, relevanceData, menuItems, soryByData, STATUS_CODE };
